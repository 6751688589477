import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
        path: '/campaign',
        name: 'Campaign',
        component: () => import(/* webpackChunkName: "campaign" */ './views/Campaign.vue')
    },
    {
        path: '/campaign/:id',
        name: 'CampaignDetail',
        component: () => import(/* webpackChunkName: "campaign detail" */ './views/CampaignDetail.vue')
    },
    {
        path: '/campaign/:id/category',
        name: 'Campaign category',
        component: () => import(/* webpackChunkName: "campaign category" */ './views/CampaignCategory.vue')
    },
    {
        path: '/campaign/:id/question',
        name: 'Campaign question',
        component: () => import(/* webpackChunkName: "campaign question" */ './views/CampaignQuestion')
    },
    {
        path: '/category',
        name: 'Category',
        component: () => import(/* webpackChunkName: "category" */ './views/Category.vue')
    },
    {
        path: '/question',
        name: 'Question',
        component: () => import(/* webpackChunkName: "question" */ './views/Question.vue')
    },
    {
        path: '/question/:id',
        name: 'QuestionDetail',
        component: () => import(/* webpackChunkName: "question detail" */ './views/QuestionDetail.vue')
    },
    {
        path: '/question/:id/comment',
        name: 'Question predefined comment',
        component: () => import(/* webpackChunkName: "question comment" */ './views/QuestionComment.vue')
    },
    {
        path: '/city',
        name: 'City',
        component: () => import(/* webpackChunkName: "question" */ './views/City.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
