<template>
    <v-app>
        <v-app-bar app dark color="grey darken-4">
            <router-link to="/">
                <div class="d-flex align-center">
                    <v-img
                        alt="AI Powered City"
                        class="mr-2"
                        src="./assets/logo.png"
                        contain
                    />
                </div>
            </router-link>

            <v-spacer></v-spacer>

            <v-btn text to="/login" v-if="!isLoggedIn">
                {{ $t('btn.login') }}
            </v-btn>

            <v-btn text to="/login" v-if="isLoggedIn">
                <span class="mr-2">{{ user.email }}</span>
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-on="on" v-bind="attrs">
                        {{ localeSelection }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item-group v-model="localeSelection">
                        <v-list-item
                            v-for="item in locales"
                            :key="'lc_'+item.code"
                            :value="item.code"
                        >
                            {{ item.localName }}
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main>
            <router-view/>
        </v-main>

        <v-snackbar v-model="snackVisible">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    icon
                    v-if="snackCloseable"
                    v-bind="attrs"
                    @click="snackVisible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-app>
</template>

<script>
import { LOCALES } from './constants'
export default {
    name: 'App',
    data: () => ({
        locales: LOCALES,
        snackText: "",
        snackVisible: false,
        snackCloseable: false
    }),
    computed: {
        user() {
            return this.$store.state.user;
        },
        isLoggedIn() {
            return this.$store.isLoggedIn();
        },
        snackbar() {
            return this.$store.state.snack;
        },
        localeSelection: {
            get: function () {
                return this.$i18n.locale
            },
            set: function (newVal) {
                this.$i18n.locale = newVal;
                this.$vuetify.lang.current = newVal;
            }
        }
    },
    watch: {
        //TODO: move global snackbar logic to own component
        snackbar: function(newVal, oldVal) {
            if (newVal && newVal != oldVal) {
                var s = this.$store.removeSnack();
                this.snackVisible = true;
                this.snackText = s.message;
                this.snackCloseable = s.close;
            }
        }
    }
};
</script>
