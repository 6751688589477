export const IS_DEBUG = process.env.VUE_APP_DEBUG || false;

export const ROLE_TYPE = {
    ADMIN: "ADMIN"
};

export const LOCALES = [
    { code: "en", name: "English", localName: "English" },
    { code: "sk", name: "Slovak", localName: "Slovenčina" },
];
