<template>
    <v-container>
        <v-row class="text-center">
            <v-col>
                <h1>
                    {{ $t('title') }}
                </h1>
                <p v-if="!isLoggedIn">
                    <i18n path="instruction_when_not_logged_in">
                        <router-link to="/login">{{ $t('login_link_title') }}</router-link>
                    </i18n>
                </p>
            </v-col>
        </v-row>
        <v-row v-if="isLoggedIn">
            <v-col>
                <router-link to="/campaign">
                    {{ $t('campaign.title') }}
                </router-link>
                <br/>
                <router-link to="/category">
                    {{ $t('category.title') }}
                </router-link>
                <br/>
                <router-link to="/question">
                    {{ $t('question.title') }}
                </router-link>
                <br/>
                <router-link to="/city">
                    {{ $t('city.title') }}
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Home',
    computed: {
        isLoggedIn() {
            return this.$store.isLoggedIn();
        }
    },
    i18n: {
        messages: {
            en: {
                "title": "Welcome to AI Powered City Admin!",
                "instruction_when_not_logged_in": "Please, {0} in order to continue.",
                "login_link_title": "sign in"
            },
            sk: {
                "title": "Vitajte v AI Powered City Admin!",
                "instruction_when_not_logged_in": "Prosím, {0}, aby ste mohli pokračovať.",
                "login_link_title": "prihláste sa"
            }
        }
    }
}
</script>
