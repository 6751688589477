export default {
    state: {
        token: null,
        user: {
            id: null,
            name: "",
            email: "",
            phone: "",
            city: "",
            role: ""
        },

        snack: null, // { message, type, timeout, close }
    },

    isLoggedIn() {
        return this.state.user.id !== null;
    },

    setAuthToken(token) {
        this.state.token = token;
    },

    clearAuthToken() {
        this.state.token = null;
    },

    setUser(data) {
        this.state.user.id = data && data.id;
        this.state.user.name = data && data.name;
        this.state.user.email = data && data.email;
        this.state.user.phone = data && data.phone;
        this.state.user.city = data && data.permanentAddressCity;
        this.state.user.role = data && data.roleType;
    },

    clearUser() {
        this.state.user.id = null;
        this.state.user.name = "";
        this.state.user.email = "";
        this.state.user.phone = "";
        this.state.user.city = "";
        this.state.user.role = "";
    },

    clearAll() {
        this.clearAuthToken();
        this.clearUser();
    },

    setSnack(msg) {
        this.state.snack = { message: msg, type: "info", close: true };
    },
    removeSnack() {
        var s = this.state.snack;
        this.state.snack = null;
        return s;
    }
}