import Vue from 'vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import store from './store'
import './plugins/axios'

import App from './App.vue'

Vue.mixin({
    methods: {
        // translation helper for use in form validators
        $tr: function(rules) {
            const $t = this.$t.bind(this);
            return rules.map(rule => rule.bind(null, $t));
        },
        // displays a snackbar with translated notification message
        $tnotify(msg, err) {
            if (err && err.response.data.errorCode) {
                this.$store.setSnack(this.$t("msg.apiError." + err.response.data.errorCode));
            } else {
                this.$store.setSnack(this.$t(msg));
            }
        }
    },
    computed: {
        // make store available
        $store: () => store
    },
});

Vue.filter('formatDateTime', function (value, locale) {
    if (!value) return ''
    return new Date(value).toLocaleString(locale)
})

new Vue({
    router,
    vuetify,
    i18n,
    data: {
        state: store.state // plug entire state here to make it reactive
    },
    render: h => h(App)
}).$mount('#app');
